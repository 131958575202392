<template>
  <div id="media-management-form">
    <breadcrumb 
      title="Dashboard" 
      subtitle="Media Management (Home)" 
      sub-title-url="/media-management/list"
      sub-sub-title="Home" 
      :sub-sub-title-url="this.$route.path"
    />

    <b-row class="lnv-main-card">
      <b-col>
        <b-card>
          <h4 v-if="!isEdit">Create New Media</h4>
          <br />

          <div v-if="isLoading" style="height: 500px"><div class="loading loading-big"></div></div>
          <div v-else>
            <div v-if="isUploadLoading" class="loading-container">
              <div class="loading loading-big"></div>
            </div>
  
            <form @submit.prevent="isEdit ? handleEditMedia() : handleAddMedia()">
              <b-row class="form">
                <b-col lg="12">
                  <b-form-group label-cols-sm="2" label-for="media-name">
                    <template v-slot:label>
                      Name <span class="required-star">*</span>
                    </template>
                    <b-form-input
                      id="media-name"
                      v-model="form.name"
                      :disabled="isCap || isCapAdmin"
                      trim
                      @keyup="formErrors.name = ''"
                    ></b-form-input>
                    <span v-if="formErrors.name" class="invalid-field">{{
                      formErrors.name
                    }}</span>
                  </b-form-group>
                </b-col>
  
                <b-col lg="12">
                  <b-form-group label-cols-sm="2" label-for="media-type">
                    <template v-slot:label>
                      Media Type <span class="required-star">*</span>
                    </template>
                    <b-form-radio-group
                      id="media-type"
                      v-model="form.mediaType"
                      :disabled="isCap || isCapAdmin"
                      button-variant="outline-primary"
                      buttons
                    >
                      <b-form-radio value="VIDEO">Video</b-form-radio>
                      <b-form-radio value="PHOTO">Photos</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>
                <b-col lg="12">
                  <!-- VIDEO -->
                  <b-form-group
                    v-if="form.mediaType === 'VIDEO'"
                    content-cols-lg="10"
                    label-for="url"
                  >
                    <template v-slot:label>
                      Upload Video <span class="required-star">*</span>
                    </template>
                    <b-form-file
                      ref="videoInput"
                      :disabled="isCap || isCapAdmin"
                      accept=".mp4"
                      @change="uploadVideoFile"                    
                    ></b-form-file>
                    <small>* Video in MP4 format</small>
                    <b-form-input
                      id="url"
                      v-model="videoUrl"
                      :disabled="isCap || isCapAdmin"
                      type="url"
                      style="display: none"
                      @keyup="formErrors.url = ''"
                    ></b-form-input>
                    <span v-if="formErrors.url" class="invalid-field">{{
                      formErrors.url
                    }}</span>
                  </b-form-group>
  
                  <!-- PHOTO  -->
                  <b-form-group v-else content-cols-lg="10" label-for="upload">
                    <template v-slot:label>
                      Upload Photos <span class="required-star">*</span>
                    </template>
                    <b-form-file
                      ref="imgInput"
                      accept="image/jpeg, image/png"
                      multiple
                      style="display: none"
                      @change="addPreviewImage"
                    ></b-form-file>
                    
                    <div class="d-flex">
                      <template v-if="thumbnailList.length > 0">
                        <span
                          v-for="(img, index) in thumbnailList"
                          :key="index"
                          class="thumbnail-wrapper"
                        >
                          <i
                            v-if="!isCap && !isCapAdmin"
                            class="fa fa-times fa-lg"
                            aria-hidden="true"
                            @click.prevent="removePreviewImage(index,img.id || null)"
                          ></i>
                          <a target="_blank" :href="getPreviewSrc(img)">
                            <img
                              :src="getPreviewSrc(img)"
                              alt="thumbnail"
                              class="thumbnail"
                              @error="replaceImageByDefault"
                            />
                          </a>
                        </span>
                      </template>
                      <div
                        v-if="!isCap && !isCapAdmin"
                        class="thumbnail add"
                        @click.prevent="
                          () => {
                            $refs.imgInput.$refs.input.click();
                          }
                        "
                      ></div>
                    </div>
                    <small>* Photos in JPEG or PNG format</small>
                    <span
                      v-if="formErrors.photoBase64List"
                      class="invalid-field"
                      >{{ formErrors.photoBase64List }}</span>
                  </b-form-group>
                </b-col>
  
                <b-col lg="12">
                  <b-form-group label="Date">
                    <DateRangePicker
                      v-if="!isCap && !isCapAdmin"
                      ref="picker"
                      v-model="dateRange"
                      :opens="datePicker.opens"                    
                      :locale-data="{ firstDay: 1, format: 'YYYY-MM-DD' }"
                      :auto-apply="datePicker.autoApply"
                      :ranges="datePicker.ranges"
                      @update="updateDateValues"
                      >
                      <div slot="input" slot-scope="picker" style="width: 100%; float: left;">
                        <span v-if="picker.startDate && picker.endDate">{{ formatDate(picker.startDate) }} to {{ formatDate(picker.endDate) }}</span>
                      </div>
                    </DateRangePicker>
                    <b-form-input 
                      v-else 
                      :disabled="isCap || isCapAdmin"
                      :value="formatDate(dateRange.startDate) +` to `+ formatDate(dateRange.endDate)"
                    >
                      
                    </b-form-input>
                  </b-form-group>
                </b-col>
  
                <b-col lg="12">
                  <b-form-group label="Country">
                    <Multiselect
                      v-model="selectedCountry"
                      placeholder="Select country"
                      :searchable="true"
                      :disabled="true"
                      :options="countryOptions"
                      label="text"
                      track-by="id"
                      select-label
                      deselect-label
                    />
                  </b-form-group>
                </b-col>
  
                <b-col lg="12">
                  <b-form-group label="Display on">
                    <Multiselect
                      ref="multiselect-display"
                      v-model="selectedDisplayType" 
                      :disabled="isCap || isCapAdmin"
                      :options="displayOptions"                      
                      label="text"
                      track-by="value"
                      :allow-empty="false"
                      :show-labels="false"
                    ></Multiselect>
                  </b-form-group>
                </b-col>
               
                <!-- PRODUCT_GROUP -->
                <b-col v-if="selectedDisplayType.value === 'PRODUCT_GROUP'" lg="12" class="d-flex flex-column">
                  <div>
                    <label class="text-label">Product Group</label>
                    <span class="required-star">*</span>
                  </div>
                  <div class="d-flex">
                    <div class="d-flex flex-column w-100">
                      <div class="d-flex align-items-center">
                        <div class="w-100">
                          <div class="w-100 d-flex align-items-center">
                            <div class="w-100 d-inline-flex">
                              <Multiselect
                                v-model="selectedProductGroup"
                                v-validate="'required'"
                                placeholder="Select option"
                                :searchable="true"
                                :disabled="isCap || isCapAdmin"
                                :options="productGroupOptions"
                                :close-on-select="false"
                                :multiple="true"
                                select-label
                                deselect-label
                              />
                              <button
                              class="btn btn-primary d-flex align-items-center ml-3 mt-1"
                              :disabled="isCap || isCapAdmin"
                              @click.prevent="selectAll('productGroupOptions')"
                              >
                              Select All
                            </button>
                          </div>
                        </div>
                        <div class="mt-3">
                          <span
                          v-if="formErrors.productGroupList"
                          class="invalid-field"
                          >{{ formErrors.productGroupList }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </b-col>
              
                <!-- PRODUCT_CATEGORY -->
                <b-col v-if="selectedDisplayType.value === 'PRODUCT_CATEGORY'" lg="12" class="d-flex flex-column">
                  <div>
                    <label class="text-label">Product Category</label>
                    <span class="required-star">*</span>
                  </div>
                  <div class="d-flex">
                    <div class="d-flex flex-column w-100">
                      <div class="d-flex align-items-center">
                          <div class="w-100">
                            <div class="w-100 d-flex align-items-center">
                              <div class="w-100 d-inline-flex">
                                <Multiselect
                                  v-model="selectedProductCategory"
                                  v-validate="'required'"
                                  placeholder="Select option"
                                  :searchable="true"
                                  :disabled="isCap || isCapAdmin"
                                  :options="productCategoryOptions"
                                  :close-on-select="false"
                                  :multiple="true"
                                  select-label
                                  deselect-label
                                />
                                <button
                                  class="btn btn-primary d-flex align-items-center ml-3 mt-1"
                                  :disabled="isCap || isCapAdmin"
                                  @click.prevent="selectAll('productCategoryOptions')"
                                >
                                  Select All
                                </button>
                              </div>
                            </div>
                            <div class="mt-3">
                              <span
                                v-if="formErrors.productCategoryList"
                                class="invalid-field"
                                >{{ formErrors.productCategoryList }}</span>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </b-col>
                
                <!-- MODEL_NAME -->
                <b-col v-if="selectedDisplayType.value === 'MODEL_NAME' && canEdit" lg="12" class="d-flex flex-column">
                  <div>
                    <label class="text-label">Model Name Upload</label>
                    <span class="required-star">*</span>
                  </div>
                  <div class="d-flex">
                    <div class="d-flex flex-column w-100">
                      <div class="d-flex align-items-center">
                        <div class="w-100">
                          <div class="w-100 d-flex align-items-center">
                            <div class="w-100 d-inline-flex">
                              <b-form-file
                                id="fileUploader"
                                ref="fileUploader"
                                v-model="fileUpload"
                                name="file"
                                accept=".xlsx"
                                size="lg"
                                @change="handleFileUpload($event)"
                              ></b-form-file>
                              <button
                                type="button"
                                class="btn btn-warning d-flex align-items-center ml-3 mt-1"
                                @click.prevent="resetFileUploader"
                              >
                                Reset file
                              </button>
                            </div>
                          </div>
                          <div class="mt-3">
                            <span
                              v-if="formErrors.modelNameList"
                              class="invalid-field"
                              >{{ formErrors.modelNameList }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2">
                        <button
                          type="button"
                          class="ml-0 p-0 mt-1 btn-link btn text-info width-fit-content"
                          @click.prevent="doExport()"
                        >
                            Model Name Template
                          <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </b-col>
  
                <b-col v-if="selectedDisplayType.value === 'MODEL_NAME'">
                  <div v-if="selectedModelName.length > 0">
                      <div class="col-lg-12 d-inline-flex">
                        <div class="col-lg-4 pl-0">
                          <label class="text-label">Model Name</label>
                        </div>
                      </div>
                      <div v-for="list in selectedModelName" :key="list" class="col-lg-12 d-inline-flex mb-4">
                        <div class="col-lg-4 pl-0">
                          <input 
                            :value="list"
                            class="form-control" 
                            :disabled="true"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                </b-col>
                
              </b-row>
  
              <!-- SUBMIT -->
              <b-row v-if="canEdit" class="mt-5">
                <b-col lg="12">
                  <button type="submit" class="btn btn-secondary pull-right">
                    {{ isEdit ? 'Update Media' : 'Create New Media' }}
                  </button>
                </b-col>
              </b-row>
            </form>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>


<script>
/**
 * NOTE : 
 * CAP admin can only VIEW
 * ONLY country head and country media can add/edit
 * 
*/
import breadcrumb from "@/components/breadcrumb/breadcrumb";
import DateRangePicker from "@/components/ui/vue2-daterange-picker";
import Multiselect from "vue-multiselect";
import { convertDateFormat } from "@/utils/helpers";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import default_image from "@/assets/default_image.jpg";
import { COUNTRY_LIST_REQUEST, UPLOAD_FILE_REQUEST } from "@/store/modules/utility/actions";
import { PRODUCT_CATEGORY_AND_GROUP_REQUEST, ADD_MEDIA_REQUEST, MEDIA_BY_ID_REQUEST, UPDATE_MEDIA_REQUEST } from "@/store/modules/media/actions";
import {
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR
} from "@/store/modules/ui/actions";
import { homeFormMixin } from "../_mixins/home-form";
import { fileMixin } from "../_mixins/file";

const today = new Date();


export default {
  name: "MediaFormPage",
  components: {
    breadcrumb,
    Multiselect,
    DateRangePicker,
  },
  mixins: [homeFormMixin,fileMixin],
  computed: {
    canEdit() {
      return this.isCountryAdmin || this.isCountryMedia
    },
    selectedDisplayType: {
      get() { return this.displayOptions.find(el => el.value == this.form.displayOn) },
      set(obj) { 
        if(this.isEdit){
          // Clear display unit
          this.form.mediaManagementDisplayUnitUpdateDto = []
        }
        this.form.displayOn = obj.value 
      }
    },
    selectedCountry: {
      get() { 
        const countryId = this.form.mediaManagementCountryDto.countryIdList[0]
        return this.countryOptions.find(el => el.id === countryId) 
      },
      set(obj) { 
        if(this.isEdit) return
        else this.form.mediaManagementCountryDto.countryIdList[0] = obj.id        
      }
    },
    isEdit() {
      return !!this.$route.params.id
    },
    videoUrl: {   
      get() {
        var url = ""
        if(this.isEdit && this.form.mediaManagementContentUpdateDto) {
          let contentList = this.form.mediaManagementContentUpdateDto  
          url = contentList[0].videoUrl
        } else {
          url = this.form.mediaManagementContentDto.url
        }
        return url
      },
      set(obj) { 
        if(this.isEdit) {
          this.form.mediaManagementContentUpdateDto = []
          this.form.mediaManagementContentUpdateDto.push({videoUrl: obj})
        } else {
          this.form.mediaManagementContentDto.url = obj
        }
      }
    },
    selectedProductGroup: {
      get() {
        let list = []
        if(this.isEdit) {
          list = this.form.mediaManagementDisplayUnitUpdateDto.map((el) => el.productGroup);
        } else {
          list = this.form.mediaManagementDisplayUnitDto.productGroupList
        }
        return list
      },
      set(list) {         
        if(this.isEdit) {
          this.form.mediaManagementDisplayUnitUpdateDto = []
          this.form.mediaManagementDisplayUnitUpdateDto = list.map((el) => ({productGroup : el}));
        } else {
          this.form.mediaManagementDisplayUnitDto.productGroupList = list
        }
      }
    },
    selectedProductCategory: {
      get() {
        let list = []
        if(this.isEdit) {
          list = this.form.mediaManagementDisplayUnitUpdateDto.map((el) => el.productCategory);
        } else {
          list = this.form.mediaManagementDisplayUnitDto.productCategoryList
        }
        return list
      },
      set(list) {         
        if(this.isEdit) {
          this.form.mediaManagementDisplayUnitUpdateDto = []
          this.form.mediaManagementDisplayUnitUpdateDto = list.map((el) => ({productCategory : el}));
        } else {
          this.form.mediaManagementDisplayUnitDto.productCategoryList = list
        }
      }
    },
    selectedModelName: {
      get() {
        let list = []
        if(this.isEdit && this.form.mediaManagementDisplayUnitUpdateDto) {          
          list = this.form.mediaManagementDisplayUnitUpdateDto.map((el) => el.productModelName);
        } else {
          list = this.form.mediaManagementDisplayUnitDto.modelNameList
        }
        return list
      },
      set(list) {
        if(this.isEdit) {
          this.form.mediaManagementDisplayUnitUpdateDto = []
          this.form.mediaManagementDisplayUnitUpdateDto = list.map((el) => ({productModelName : el}));
        } else {
          this.form.mediaManagementDisplayUnitDto.modelNameList = list
        }
      }
    },
  },
  data() {
    return {      
      isLoading: false,
      isUploadLoading: false,
      countryOptions: [],
      productGroupOptions: [],
      productCategoryOptions: [],      
      thumbnailList: [],      
      displayOptions: [
        { text: "All", value: "ALL"},
        { text: "By Product Group", value: "PRODUCT_GROUP"},
        { text: "By Category", value: "PRODUCT_CATEGORY"},
        { text: "By Model Name", value: "MODEL_NAME"},
      ],
      dateRange: {
        startDate:  convertDateFormat(new Date(today.getFullYear(), today.getMonth(), 1), "YYYY-MM-DD"),
        endDate:  convertDateFormat(new Date(today.getFullYear(), today.getMonth() + 1, 0), "YYYY-MM-DD")         
      },
      datePicker: {
        opens: "center",
        autoApply: true,
        ranges: false,
      },
      isCap: this.$store.getters.isCap,
      isCapAdmin: this.$store.getters.isCapAdmin,    
      isCountryAdmin: this.$store.getters.isCountryAdmin,
      isCountryMedia: this.$store.getters.isCountryMedia,
      fileUpload: [],      
    };
  },
  methods: {
    // =========== START: VIDEO ===========
    async uploadVideoFile(event) {
      this.isUploadLoading = true

      // remove error msg 
      this.formErrors.url = "";

      let data = new FormData();
      const file = await event.target.files[0];

      data.append('file', file)

      await this.$store.dispatch(UPLOAD_FILE_REQUEST, data).then(res => {
        if (res.resultCode === 0) {                    
          this.videoUrl = cloneDeep(res.content[0])
        }
      })
      .finally(() => {
        this.isUploadLoading = false
      })

      // Call Upload Video API here
    },
    // =========== END: VIDEO ===========
    // =========== START: IMAGES ===========
    replaceImageByDefault(e) {
			e.target.src = default_image;
		},
    async addPreviewImage(event) {
      // remove error msg 
      this.formErrors.photoBase64List = "";

      const file = await event.target.files[0];
      this.thumbnailList.push(file);
      this.$refs.imgInput.reset();
      
      this.getBase64(file).then(data => {
        if(this.isEdit) {
          this.form.mediaManagementContentUpdateDto.push({photoBase64: data})
        } else {
            this.form.mediaManagementContentDto.photoBase64List.push(data)
          }
        });
    },
    removePreviewImage(index,id) {
      this.thumbnailList.splice(index, 1);
      if(this.isEdit) { 
        if(id) {
          this.form.mediaManagementContentUpdateDto.splice(this.form.mediaManagementContentUpdateDto.findIndex(el => el.id === id), 1);
        } else {                    
          this.form.mediaManagementContentUpdateDto.splice(index, 1);
        }
      } else {
        this.form.mediaManagementContentDto.photoBase64List.splice(index, 1);
      }
    },
    getPreviewSrc(file) {      
      if(this.isEdit && file.hasOwnProperty('imageUrl')) return file.imageUrl
      else return URL.createObjectURL(file);
    }, 
    // =========== END: IMAGES ===========
    // =========== START: GET INFO API ===========
    async getCountryList() {
      await this.$store.dispatch(COUNTRY_LIST_REQUEST, {})
      .then(res => {
        if (res.resultCode === 0) {
          this.countryOptions = cloneDeep(res.content)
        }
      })
    },
    async getProductCategoryAndGroup() {
      await this.$store.dispatch(PRODUCT_CATEGORY_AND_GROUP_REQUEST)
      .then(res => {
        if (res.resultCode === 0) {                    
          this.productGroupOptions = cloneDeep(res.content.productGroupList)
          this.productCategoryOptions = cloneDeep(res.content.productCategoryList)
        }
      })
    },
    async getMediaById() {
      await this.$store.dispatch(MEDIA_BY_ID_REQUEST,this.$route.params.id)
      .then(res => {
        if (res.resultCode === 0) {
          this.form = cloneDeep(this.formatEditFormData(res.content))
        } else {
          this.$router.push("/media-management/list");
        }
      })
    },
    // =========== END: GET INFO API ===========   
    // =========== START: SUBMIT API =========== 
    handleAddMedia() {
      if (!this.checkAddFormValidity()) {
        return;
      } else {
        this.isLoading = true
        this.$store.dispatch(ADD_MEDIA_REQUEST, this.form)
        .then(res => {              
          if (res.resultCode === 0) {
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Media Successfully Added."
            });
            this.$router.push("/media-management/list");
          }
        })
        .catch(err => {
          this.$store.dispatch(NOTIFICATION_ERROR, {
            message: err
          });
        })
        .finally(() => this.isLoading = false);
      }
    },
    handleEditMedia() {
      if (!this.checkEditFormValidity()) {
        return;
      } else {
        this.isLoading = true
        this.$store.dispatch(UPDATE_MEDIA_REQUEST, this.form)
        .then(res => {              
          if (res.resultCode === 0) {
            this.$store.dispatch(NOTIFICATION_SUCCESS, {
              message: "Media Successfully Added."
            });
            this.$router.push("/media-management/list");
          }
        })
        .catch(err => {
          this.$store.dispatch(NOTIFICATION_ERROR, {
            message: err
          });
        })
        .finally(() => this.isLoading = false);
      }
    },
    // =========== END: SUBMIT API =========== 
    // =========== START: MISC ===========
    formatEditFormData(data) {
      let formattedData = {
        id: data.id,
        name: data.name,
        displayOn: data.displayOn,
        startDate: Number(convertDateFormat(data.startDate, "x")),
        endDate: Number(convertDateFormat(data.endDate, "x")),
        mediaType: data.mediaType,
        mediaManagementCountryDto: {
          countryIdList: [data.mediaManagementCountryListResponseDto[0].countryId]
        },
        mediaManagementDisplayUnitUpdateDto: data.mediaManagementDisplayUnitListResponseDto
      }

      this.dateRange.startDate = convertDateFormat(data.startDate, "YYYY-MM-DD")
      this.dateRange.endDate = convertDateFormat(data.endDate, "YYYY-MM-DD")
      
      if(formattedData.mediaType === 'PHOTO'){
        this.thumbnailList = data.mediaManagementContentListResponseDto
        formattedData.mediaManagementContentUpdateDto = data.mediaManagementContentListResponseDto
      } else {
        let linkObj = data.mediaManagementContentListResponseDto.find(el => el.mediaType === "VIDEO") 
        formattedData.mediaManagementContentUpdateDto = [
          {
            id: linkObj.id,
            videoUrl: linkObj.url,
          }
        ]
      }            
      return formattedData
    },
    selectAll(type) {
      if (type === "productGroupOptions") {
        this.selectedProductGroup = [];
        this.selectedProductGroup = this.productGroupOptions;
      }
      if (type === "productCategoryOptions") {
        this.selectedProductCategory = [];
        this.selectedProductCategory = this.productCategoryOptions;
      }   
    },
    formatDate(date) {
      return convertDateFormat(date, "DD-MM-YYYY");
    },
    updateDateValues() {
      this.form.startDate = Number(moment(this.dateRange.startDate).format('x'));
      this.form.endDate = Number(moment(this.dateRange.endDate).format('x'));
    }, 
    // =========== END: MISC ===========
  },
  async mounted() {    
    this.isLoading = true

    let promises= [
      this.getCountryList(),
      this.getProductCategoryAndGroup()
    ]

    if(this.isEdit) {
      promises.push(this.getMediaById())
    }

    await Promise.all(promises)
    if (!this.isEdit && this.canEdit) {
      this.form.mediaManagementCountryDto.countryIdList.push(this.$store.getters.getCountryId)
    }

    this.isLoading = false
  },
};
</script>
<style lang="scss">
#media-management-form {
  .thumbnail-wrapper {
    margin-right: 16px;
    float: inline-start;
    position: relative;
    .fa {
      float: left;
      left: 90px;
      top: -5px;
      z-index: 10;
      position: absolute;
      color: #da3501;
      cursor: pointer;
    }
  }
  .thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    position: relative;
    float: left;
    cursor: pointer;

    &.add {
      border: 1px dashed #dddddd;

      &::after {
        content: " ";
        position: absolute;
        display: block;
        background-color: #dddddd;
        height: 1px;
        width: 28px;
        z-index: 9;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &::before {
        content: " ";
        position: absolute;
        display: block;
        background-color: #dddddd;
        width: 1px;
        height: 28px;
        z-index: 9;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .form.row {
    .form-group {
      margin-bottom: 2rem !important;
    }
  }

  .multiselect__single { 
    background: transparent;  
  }
  .reportrange-text {
    display: inline-flex;
    align-items: center;
  }
  .btn {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-file {
    height: calc(1.5em + 1rem + 13px);
  }
  .btn-outline-primary.disabled.active{
    background-color: #F70201;
    color: #fff;
  }
  .loading-container {
    height: 100%;
    width: 100%;
    background-color: #80808057;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 15px;
  }
}
</style>
