export const MEDIA_LIST_REQUEST = "MEDIA_LIST_REQUEST";
export const MEDIA_LIST_SUCCESS = "MEDIA_LIST_SUCCESS";
export const MEDIA_LIST_ERROR = "MEDIA_LIST_ERROR";

export const ADD_MEDIA_REQUEST = "ADD_MEDIA_REQUEST";
export const ADD_MEDIA_SUCCESS = "ADD_MEDIA_SUCCESS";
export const ADD_MEDIA_ERROR = "ADD_MEDIA_ERROR";

export const UPDATE_MEDIA_REQUEST = "UPDATE_MEDIA_REQUEST";
export const UPDATE_MEDIA_SUCCESS = "UPDATE_MEDIA_SUCCESS";
export const UPDATE_MEDIA_ERROR = "UPDATE_MEDIA_ERROR";

export const PRODUCT_CATEGORY_AND_GROUP_REQUEST = "PRODUCT_CATEGORY_AND_GROUP_REQUEST";
export const PRODUCT_CATEGORY_AND_GROUP_SUCCESS = "PRODUCT_CATEGORY_AND_GROUP_SUCCESS";
export const PRODUCT_CATEGORY_AND_GROUP_ERROR = "PRODUCT_CATEGORY_AND_GROUP_ERROR";

export const DELETE_MEDIA_REQUEST = "DELETE_MEDIA_REQUEST";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const DELETE_MEDIA_ERROR = "DELETE_MEDIA_ERROR";

export const MEDIA_BY_ID_REQUEST = "MEDIA_BY_ID_REQUEST";
export const MEDIA_BY_ID_SUCCESS = "MEDIA_BY_ID_SUCCESS";
export const MEDIA_BY_ID_ERROR = "MEDIA_BY_ID_ERROR";

export const VALIDATE_MODEL_NAME_REQUEST = "VALIDATE_MODEL_NAME_REQUEST";
export const VALIDATE_MODEL_NAME_SUCCESS = "VALIDATE_MODEL_NAME_SUCCESS";
export const VALIDATE_MODEL_NAME_ERROR = "VALIDATE_MODEL_NAME_ERROR";