<template>
  <div>
    <p
      v-if="canEdit && currentAction === 'edit'"
      class="required-title"
      v-text="$ml.get('form_required_title')"
    ></p>
    <form
      v-if="canEdit && currentAction === 'edit'"
      @submit.prevent="handleSubmit"
    >
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label
              class="text-label"
              v-text="$ml.get('form_salesperson_name')"
            ></label>
            <span class="required-star">*</span>
            <input
              id="name"
              v-model="salesp.name"
              name="name"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.name = ''"
            />
            <span v-if="formErrors.name" class="invalid-field">{{
              formErrors.name
            }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label
              class="text-label"
              v-text="$ml.get('form_salesperson_ic_number')"
            ></label>
            <span class="required-star">*</span>
            <input
              id="icNumber"
              v-model="salesp.icNumber"
              v-validate="'alpha_num'"
              name="IC Number"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.icNumber = ''"
            />
            <span v-if="errors.first('IC Number')" class="invalid-field">{{
              errors.first("IC Number")
            }}</span>
            <span v-if="formErrors.icNumber" class="invalid-field">{{
              formErrors.icNumber
            }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label
              class="text-label"
              v-text="$ml.get('form_salesperson_gender')"
            ></label>
            <span class="required-star">*</span>
            <Multiselect
              ref="multiselect-gender"
              v-model="salesp.genderId"
              placeholder="Select Gender"
              :searchable="true"
              :options="genders"
              label="text"
              track-by="id"
              select-label
              deselect-label
              :loading="isGendersLoading"
            ></Multiselect>
            <span v-if="formErrors.genderId" class="invalid-field">{{
              formErrors.genderId
            }}</span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label
              class="text-label"
              v-text="$ml.get('form_salesperson_contact_number')"
            ></label>
            <span class="required-star">*</span>
            <input
              v-model="salesp.phoneNumber"
              v-validate="{ regex: getPhoneRegex() }"
              name="form_salesperson_phone"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.phoneNumber = ''"
            />
            <span v-if="formErrors.phoneNumber" class="invalid-field">{{
              formErrors.phoneNumber
            }}</span>
            <!-- eslint-disable vue/no-v-html -->
            <span
              v-if="
                errors.firstByRule('form_salesperson_phone', 'regex') &&
                  !formErrors.phoneNumber
              "
              class="invalid-field"
              v-html="$ml.get('invalid_phone_number')"
            ></span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label
              class="text-label"
              v-text="$ml.get('form_salesperson_email')"
            ></label>
            <span class="required-star">*</span>
            <input
              v-model="salesp.email"
              v-validate="{ regex: getEmailRegex() }"
              name="form_salesperson_email"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.email = ''"
            />
            <span v-if="formErrors.email" class="invalid-field">{{
              formErrors.email
            }}</span>
            <span
              v-if="
                errors.firstByRule('form_salesperson_email', 'regex') &&
                  !formErrors.email
              "
              class="invalid-field"
              v-html="$ml.get('invalid_email_address')"
            ></span>
          </div>
        </div>
        <div v-if="showDealerField" class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label
              class="text-label"
              v-text="$ml.get('form_salesperson_dealer_name')"
            ></label>
            <span class="required-star">*</span>
            <Multiselect
              ref="multiselect-dealer"
              v-model="salesp.dealerId"
              placeholder="Select Dealer"
              :searchable="true"
              :options="dealers"
              label="dealerName"
              track-by="id"
              select-label
              deselect-label
              :loading="isDealersLoading"
              @input="filterOutlets"
            ></Multiselect>
            <span v-if="formErrors.dealerId" class="invalid-field">{{
              formErrors.dealerId
            }}</span>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label
              class="text-label"
              v-text="$ml.get('form_salesperson_outlet_code')"
            ></label>
            <span class="required-star">*</span>
            <Multiselect
              ref="multiselect-outlet"
              v-model="salesp.outletId"
              placeholder="Select Outlet"
              :searchable="true"
              :options="outlets"
              label="dealerOutletCode"
              track-by="id"
              select-label
              deselect-label
              :loading="isOutletsLoading"
              @input="formErrors.outletId = ''"
            ></Multiselect>
            <span v-if="formErrors.outletId" class="invalid-field">{{
              formErrors.outletId
            }}</span>
          </div>
        </div>

        <div class="col-12">
          <br />
          <h4
            class="text-primary"
            v-text="$ml.get('form_salesperson_bank_information')"
          ></h4>
          <br />
        </div>
        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label
              class="text-label"
              v-text="$ml.get('form_salesperson_bank_name')"
            ></label>
            <span class="required-star">*</span>
            <Multiselect
              ref="multiselect-bank"
              v-model="salesp.bankId"
              placeholder="Select Bank"
              :searchable="true"
              :options="banks"
              label="text"
              track-by="id"
              select-label
              deselect-label
              :loading="isBanksLoading"
            ></Multiselect>

            <span v-if="formErrors.bankId" class="invalid-field">{{
              formErrors.bankId
            }}</span>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="form-group flex-grow-1">
            <label
              class="text-label"
              v-text="$ml.get('form_salesperson_bank_acc_number')"
            ></label>
            <span class="required-star">*</span>
            <input
              v-model="salesp.bankAccount"
              type="text"
              class="form-control"
              placeholder
              @keyup="formErrors.bankAccount = ''"
            />
            <span v-if="formErrors.bankAccount" class="invalid-field">{{
              formErrors.bankAccount
            }}</span>
          </div>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-lg-12">
              <button type="submit" class="btn btn-secondary pull-right">
                <span
                  v-if="!salesp.staffId"
                  v-text="$ml.get('form_salesperson_add_button')"
                ></span>
                <span
                  v-if="salesp.staffId"
                  v-text="$ml.get('form_salesperson_update_button')"
                ></span>
              </button>
              <button
                type="button"
                class="btn btn-light pull-right"
                data-dismiss="modal"
                @click="close()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- View -->
    <div v-if="canView && currentAction === 'view'">
      <table class="table">
        <tbody>
        <tr>
          <td v-text="$ml.get('form_salesperson_join_date')"></td>
          <td>{{ salesp.joinedDate }}</td>
        </tr>
          <tr>
            <td v-text="$ml.get('form_salesperson_contact_number')"></td>
            <td>{{ salesp.phoneNumber }}</td>
          </tr>
          <tr>
            <td v-text="$ml.get('form_salesperson_email')"></td>
            <td>{{ salesp.email }}</td>
          </tr>
        </tbody>
      </table>
    
      <div class="row">
        <div class="col-lg-12">
          <VueBootstrap4Table
              :classes="classes"
              :rows="salespersonOutletList"
              :columns="columns"
              :config="config"
              :total-rows="total"
              @on-change-query="onChangeQuery"
              @refresh-data="getSalespersonOutletList"
          >
            <template slot="sort-asc-icon">
              <i class="mdi mdi-sort-ascending"></i>
            </template>
            <template slot="sort-desc-icon">
              <i class="mdi mdi-sort-descending"></i>
            </template>
            <template slot="no-sort-icon">
              <i class="mdi mdi-sort-variant"></i>
            </template>
            <template slot="empty-results">
              <div
                  v-if="!isDataLoading"
                  class="bold-text"
                  v-text="$ml.get('no_salesperson_found')"
              ></div>
              <div
                  v-if="isDataLoading"
                  class="bold-text"
                  v-text="$ml.get('data_loading')"
              ></div>
            </template>

            <!-- <template slot="dealerName" slot-scope="props">
              <div class="inner-data">
                <router-link :to="{ name : 'Dealer Details ', params : { countryCode : props.row.countryCode, lppNumber : props.row.lppCode } }">
                  <span style="color:#F70201">{{ props.row.dealerName }}</span>
                </router-link>
              </div>
            </template>
            <template slot="outletName" slot-scope="props">
              <div class="inner-data">
                <router-link :to="{ name : 'Outlet Details ', params : { dealerOutletCode : props.row.outletCode } }">
                  <span style="color:#F70201">{{ props.row.outletName }}</span>
                </router-link>
              </div>
            </template> -->
          </VueBootstrap4Table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {GET_SALESPERSON_LIST_REQUEST, POST_STAFF_REQUEST} from "../../store/modules/staff/actions";
import {
  GENDER_LIST_REQUEST,
  BANK_LIST_REQUEST
} from "../../store/modules/utility/actions";
import { DEALER_LIST_REQUEST } from "../../store/modules/dealer/actions";
import { OUTLET_LIST_REQUEST } from "../../store/modules/outlet/actions";
import {
  nameValidate,
  emailValidate,
  phoneValidate, convertDateFormat
} from "../../utils/helpers";
import Multiselect from "vue-multiselect";
import {
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS
} from "../../store/modules/ui/actions";
import {Message} from "element-ui";
import VueBootstrap4Table from "@/components/ui/VueBootstrap4Table";

export default {
  name: "SalespersonForm",
  components: {
    Multiselect: Multiselect,
    VueBootstrap4Table: VueBootstrap4Table
  },
  props: {
    closeModal: { type: Function, default: () => {} },
    loadData: { type: Function, default: () => {} },
    salespSelected: {
      type: Object,
      default: () => {
        return {};
      }
    },
    canView: { type: Boolean },
    canEdit: { type: Boolean },
    currentAction: { type: String, default: "" },
    filters: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  computed: {},
  data() {
    return {
      config: {
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: false,
        card_title: "Salesperson",
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        num_of_visible_pagination_buttons: 3,
        per_page_options: [10, 25, 50, 100],
        per_page: 10,
        preservePageOnDataChange: true,
        pagination: true,
        pagination_info: true,
        multi_column_sort: false,
        global_search: {
          placeholder: "Search keyword",
          visibility: true,
          case_sensitive: false
        },
        highlight_row_hover_color: "grey"
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1
      },
      classes: {
        tableWrapper: "table-responsive",
        table: {
          "table table-bordered": true,
          "table-bordered": function() {
            return true;
          }
        },
        row: {},
        cell: {}
      },
      columns: [
        {
          name: "dealerName",
          label: "Dealer",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "outletCode",
          label: "Outlet Code",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },

        {
          name: "outletName",
          label: "Outlet Name",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
        {
          name: "outletAddress",
          label: "Address",
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left"
        },
      ],
      total: 0,
      salespersonOutletList: [],
      salesp: {
        name: this.salespSelected.name,
        email: this.salespSelected.email,
        phoneNumber: this.salespSelected.phoneNumber,
        icNumber: this.salespSelected.icNumber,
        genderId: this.salespSelected.genderId,
        dealerId: this.salespSelected.dealerId,
        outletId: this.salespSelected.outletId,
        bankId: this.salespSelected.bankId,
        bankAccount: this.salespSelected.bankAccount,
        staffId: this.salespSelected.id,
        joinedDate: this.salespSelected.joinedDate,
        type: "salespersons"
      },
      submitted: false,
      formErrors: [],
      genders: [],
      banks: [],
      outlets: [],
      dealers: [],
      userRole: "",
      isDealer: false,
      userId: 0,
      isPicOutlet: false,
      showDealerField: false,
      isGendersLoading: false,
      isOutletsLoading: false,
      isDealersLoading: false,
      isBanksLoading: false,
      isDataLoading: false,
    };
  },
  methods: {
    getPhoneRegex() {
      return phoneValidate(this.salesp.phoneNumber, 1);
    },
    getEmailRegex() {
      return emailValidate(this.salesp.email, 1);
    },
    close() {
      this.closeModal();
    },
    success() {
      this.close();
      this.loadData();
    },
    checkFormValidity() {
      this.formErrors = {};
      let error = 0;

      if (!this.salesp.name) {
        this.formErrors.name = this.$ml.get("form_salesperson_name_required");
        error++;
      } else {
        if (!nameValidate(this.salesp.name)) {
          this.formErrors.name = this.$ml.get("form_salesperson_name_invalid");
          error++;
        }
      }

      if (!this.salesp.icNumber) {
        this.formErrors.icNumber = this.$ml.get("form_salesperson_ic_required");
        error++;
      }

      if (this.showDealerField) {
        if (!this.salesp.dealerId) {
          this.formErrors.dealerId = this.$ml.get(
            "form_salesperson_dealer_name_required"
          );
          error++;
        }
      }

      if (!this.salesp.genderId) {
        this.formErrors.genderId = this.$ml.get(
          "form_salesperson_gender_required"
        );
        error++;
      }

      if (!this.salesp.email) {
        this.formErrors.email = this.$ml.get("form_salesperson_email_required");
        error++;
      } else {
        if (!emailValidate(this.salesp.email)) {
          this.formErrors.email = this.$ml.get(
            "form_salesperson_email_invalid"
          );
          error++;
        }
      }

      if (!this.salesp.phoneNumber) {
        this.formErrors.phoneNumber = this.$ml.get(
          "form_salesperson_contact_number_required"
        );
        error++;
      } else {
        if (!phoneValidate(this.salesp.phoneNumber)) {
          this.formErrors.phoneNumber = this.$ml.get("invalid_phone_number");
          error++;
        }
      }

      if (!this.salesp.outletId) {
        this.formErrors.outletId = this.$ml.get(
          "form_salesperson_outlet_required"
        );
        error++;
      }

      if (!this.salesp.bankId) {
        this.formErrors.bankId = this.$ml.get(
          "form_salesperson_bank_name_required"
        );
        error++;
      }

      if (!this.salesp.bankAccount) {
        this.formErrors.bankAccount = this.$ml.get(
          "form_salesperson_bank_acc_number_required"
        );
        error++;
      }

      return !error;
    },
    handleReset() {},
    handleCancel() {},
    handleSubmit(e) {
      this.submitted = true;
      if (this.checkFormValidity(e)) {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$store.dispatch(NOTIFICATION_REQUEST, {
              message: this.$ml.get("request_submitting")
            });
            let pdata = {};
            if (this.salesp.staffId > 0) {
              pdata.data = {}; // this.salesp;
              pdata.data.name = this.salesp.name;
              pdata.data.icNumber = this.salesp.icNumber;
              pdata.data.phoneNumber = this.salesp.phoneNumber;
              pdata.data.email = this.salesp.email;
              pdata.data.bankAccount = this.salesp.bankAccount;

              pdata.staffId = this.salesp.staffId;
              pdata.data.id = this.salesp.staffId;
              pdata.data.genderId = this.salesp.genderId
                ? this.salesp.genderId.id
                : 0;
              pdata.data.bankId = this.salesp.bankId
                ? this.salesp.bankId.id
                : 0;
              pdata.data.dealerId = this.salesp.dealerId
                ? this.salesp.dealerId.id
                : 0;
              pdata.data.outletId = this.salesp.outletId
                ? this.salesp.outletId.id
                : 0;
            } else {
              let staff = {};
              let genderId = this.salesp.genderId ? this.salesp.genderId.id : 0;
              staff.genderId = genderId;
              staff.bankId = this.salesp.bankId ? this.salesp.bankId.id : 0;
              staff.dealerId = this.salesp.dealerId
                ? this.salesp.dealerId.id
                : 0;
              staff.outletId = this.salesp.outletId
                ? this.salesp.outletId.id
                : 0;
              staff.name = this.salesp.name;
              staff.icNumber = this.salesp.icNumber;
              staff.phoneNumber = this.salesp.phoneNumber;
              staff.email = this.salesp.email;
              staff.bankAccount = this.salesp.bankAccount;
              pdata.data = [staff];
            }
            pdata.type = this.salesp.type;

            this.$store.dispatch(POST_STAFF_REQUEST, pdata).then(res => {
              if (res.resultCode === 0) {
                this.success();
                if (this.salesp.staffId) {
                  this.$store.dispatch(NOTIFICATION_SUCCESS, {
                    message: this.$ml.get("salesperson_updated")
                  });
                } else {
                  this.$store.dispatch(NOTIFICATION_SUCCESS, {
                    message: this.$ml.get("salesperson_added")
                  });
                }
              }
            });
          }
        });
      }
    },
    getGenders() {
      this.isGendersLoading = true;
      this.$store.dispatch(GENDER_LIST_REQUEST).then(res => {
        if (res.content && res.resultCode === 0) {
          this.genders = res.content;

          if (this.salesp.genderId) {
            this.salesp.genderId = this.getDataRowById(
              this.genders,
              this.salesp.genderId
            );
          }
          this.isGendersLoading = false;
        } else {
          this.isGendersLoading = false;
        }
      });
    },
    getDealers() {
      this.isDealersLoading = true;
      this.dealers = [];
      this.$store.dispatch(DEALER_LIST_REQUEST, { type: "pic" }).then(res => {
        if (res.content && res.resultCode === 0) {
          res.content.forEach(dealer => {
            if (dealer.status === "ACTIVE") {
              this.dealers.push(dealer);
            }
          });

          if (this.isDealer) {
            this.salesp.dealerId = {};
            this.salesp.dealerId.id = this.userId;
          }
          if (this.salesp.dealerId) {
            this.salesp.dealerId = this.getDataRowById(
              this.dealers,
              this.salesp.dealerId
            );
            this.filterOutlets();
          }
          this.isDealersLoading = false;
        } else {
          this.isDealersLoading = false;
        }
      });
    },
    getOutlets() {
      let outlets = [];
      this.isOutletsLoading = true;
      this.$store.dispatch(OUTLET_LIST_REQUEST, {}).then(res => {
        if (res.resultCode === 0) {
          res.content.forEach(outlet => {
            if (outlet.status === "ACTIVE") {
              outlets.push(outlet);
            }
          });
          this.outlets = outlets;

          if (this.salesp.outletId) {
            this.salesp.outletId = this.getDataRowById(
              this.outlets,
              this.salesp.outletId.id || this.salesp.outletId
            );
          }
          this.isOutletsLoading = false;
        } else {
          this.isOutletsLoading = false;
        }
      });
    },
    filterOutlets() {
      let outlets = [];
      this.isOutletsLoading = true;
      const dealer = this.salesp.dealerId;
      this.outlets = [];

      if (dealer) {
        if (dealer.picList) {
          dealer.picList.forEach(pic => {
            pic.dealerOutlets.forEach(outlet => {
              outlets.push(outlet);
            });
          });

          this.outlets = outlets;
          this.isOutletsLoading = false;
        }

        if (dealer.outletList.length > 0) {
          this.outlets = dealer.outletList;
        }

        if (this.salesp.outletId) {
          this.salesp.outletId = this.getDataRowById(
            this.outlets,
            this.salesp.outletId.id || this.salesp.outletId
          );
        }
      } else {
        this.salesp.outletId = null;
      }
      this.isOutletsLoading = false;
    },
    getBanks() {
      this.isBanksLoading = true;
      let pdata = { countryCode: this.$store.getters.getCountryCode };
      this.$store.dispatch(BANK_LIST_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.banks = res.content;
          if (this.salesp.bankId) {
            this.salesp.bankId = this.getDataRowById(
              this.banks,
              this.salesp.bankId
            );
          }
          this.isBanksLoading = false;
        } else {
          this.isBanksLoading = false;
        }
      });
    },
    getDataRowById(arr, id) {
      let c = arr.filter(function(elem) {
        if (elem.id === id) return elem;
      });
      if (c[0]) {
        return c[0];
      } else {
        return null;
      }
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.getPromoterList();
    },
    getSalespersonOutletList() {
      let pdata = {
        ccf: this.filters.countrySelected.value,
        fyf: this.filters.yearSelected.value,
        qf: this.filters.quarterSelected.value,
        spif: this.salesp.staffId,
      };
      this.salespersonOutletList = [];
      this.isDataLoading = true;

      if (this.isCapAdmin && this.isCap && !this.countryId) {
        return Message({
          message: "Select a country",
          type: "error",
          duration: 5 * 1000
        });
      }

      this.$store.dispatch(GET_SALESPERSON_LIST_REQUEST, pdata).then(res => {
        if (res.content && res.resultCode === 0) {
          this.salespersonOutletList = res.content;
          this.total = this.salespersonOutletList.length;
          this.isDataLoading = false;
        } else {
          this.isDataLoading = false;
        }
      });
    },
  },
  created() {},
  mounted() {
    this.userRole = this.$store.getters.getUserRole;
    this.userId = this.$store.getters.getUserId;
    this.isDealer = this.$store.getters.isDealer;
    this.isPicOutlet = this.$store.getters.isPicOutlet;

    if (this.isDealer) {
      this.showDealerField = false;
      this.salesp.dealerId = {};
      // this.salesp.dealerId.id = this.userId;
    } else if (this.isPicOutlet) {
      this.showDealerField = false;
    } else {
      this.showDealerField = true;
      this.getDealers();
    }
    this.getOutlets();
    this.getGenders();
    this.getBanks();
    this.salesp.joinedDate = convertDateFormat(this.salesp.joinedDate, "YYYY-MM-DD");
    this.getSalespersonOutletList();
  }
};
</script>
