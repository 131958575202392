import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

const startOfMonth = Number(moment().startOf('month').format('x'));
const endOfMonth   = Number(moment().endOf('month').format('x'));
const addFormObj = {
  name: "",
  mediaType: "PDF",
  status: "ACTIVE",
  displayOn: "ALL", 
  displayAt: "ALL", 
  mediaTabsOption: "PROMOTION",
  mediaManagementContentDto: {
    pdfBase64: "",
    photoBase64List: [],    
  },
  mediaManagementCountryDto: {
    countryIdList: []
  },
  startDate: startOfMonth,
  endDate: endOfMonth,
  mediaManagementDisplayUnitDto: {
    productGroupList: [],
    productCategoryList: [],
    modelNameList: [],
  },
  displayBy: "ALL", //ALL or DEALERS
  mediaManagementDealersDto: {
    prmLppNameList: []
  }
}

export const promoFormMixin = {
  data() {
    return {
      form: cloneDeep(addFormObj),
      formErrors: [], 
    }
  },
  methods: {
    getCommonValidation() {
      let haveError = false;
      if (this.form.name === null || this.form.name === "") {
        this.formErrors.name = "Name is required.";
        haveError = true;
      }
      return haveError;
    },
    checkAddFormValidity() {
      this.formErrors = {};
      let haveError = false;

      haveError = this.getCommonValidation()

      if (
        this.form.mediaManagementContentDto.photoBase64List.length <= 0 &&
        this.form.mediaType === "PHOTO"
      ) {
        this.formErrors.photoBase64List = "Photo is required.";
        haveError = true;
      }
      if (this.form.mediaType === "PDF" && this.form.mediaManagementContentDto.pdfBase64 ==="") {
        this.formErrors.pdfBase64 = "Pdf is required.";
        haveError = true;
      }
      if(this.form.displayOn === "PRODUCT_GROUP"){
        if(this.form.mediaManagementDisplayUnitDto.productGroupList.length === 0){
          this.formErrors.productGroupList = "At least one product group must be selected";
          haveError = true;
        }
      } 
      if(this.form.displayOn === "PRODUCT_CATEGORY"){
        if(this.form.mediaManagementDisplayUnitDto.productCategoryList.length === 0){
          this.formErrors.productCategoryList = "At least one product category must be selected";
          haveError = true;
        }
      } 
      if(this.form.displayOn === "MODEL_NAME"){
        if(this.form.mediaManagementDisplayUnitDto.modelNameList.length === 0){
          this.formErrors.modelNameList = "At least one model must be provided";
          haveError = true;
        }
      } 
      if(this.form.displayBy === "DEALERS"){
        if(this.form.mediaManagementDealersDto.prmLppNameList.length === 0){
          this.formErrors.prmLppNameList = "At least one dealer must be provided";
          haveError = true;
        }
      } 

      return haveError == false;
    },
    checkEditFormValidity() {
      this.formErrors = {};
      let haveError = false;

      haveError = this.getCommonValidation()

      if (
        this.thumbnailList.length <= 0 &&
        this.form.mediaType === "PHOTO"
      ) {
        this.formErrors.photoBase64List = "Photo is required.";
        haveError = true;
      }
      if (this.form.mediaType === "PDF" && this.form.mediaManagementDisplayUnitUpdateDto.pdfBase64 ==="") {
        this.formErrors.pdfBase64 = "Pdf is required.";
        haveError = true;
      }
      if(this.form.displayOn === "PRODUCT_GROUP" && this.form.mediaManagementDisplayUnitUpdateDto.length === 0){        
          this.formErrors.productGroupList = "At least one product group must be selected";
          haveError = true;        
      } 
      if(this.form.displayOn === "PRODUCT_CATEGORY" && this.form.mediaManagementDisplayUnitUpdateDto.length === 0){        
          this.formErrors.productCategoryList = "At least one product category must be selected";
          haveError = true;
      } 
      if(this.form.displayOn === "MODEL_NAME" && this.form.mediaManagementDisplayUnitUpdateDto.length === 0){        
          this.formErrors.modelNameList = "At least one product category must be selected";
          haveError = true;
      } 
      if(this.form.displayBy === "DEALERS"){
        if(this.form.mediaManagementDealersUpdateDto.length === 0){
          this.formErrors.prmLppNameList = "At least one dealer must be provided";
          haveError = true;
        }
      } 

      return haveError == false;
    }
  }
}